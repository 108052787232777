// @ts-nocheck
import Img from 'gatsby-image';
import React, { useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useGlobalLayoutQuery } from '../../hooks/index';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles/index';
import formatPrice from '../../utils/formatPrice';
import LinkTool from '../shared/LinkTool';
import { SettingsContext } from '../../contexts';
import StarsReview from '../shared/StarsReview';
import { decode } from 'shopify-gid';
import { useLocale } from '../../hooks/layout/useLocale';
import paper from '../../../static/paperBackground/paper.jpeg';

interface Props {
  id: string;
  title: string;
  slug: string;
  sku: string;
  stock: boolean;
  price: string;
  thumbnailImages: {
    fluid: string;
  };
  invertedImages: boolean;
  isFullScreen?: boolean;
  productTag: string;
  compareAtPrice: string;
  isMobileGrid: string;
  isBackgroundTransparent?: boolean;
  index: number;
  pageAllProduct: boolean;
  videoThumbnailAtNumber3: string;
  contentThumbnailAtNumber3: string;
  productId: any;
  variants?: any;
  thumbnailVariantsImages?: any;
}

export default function ProductThumbnail(props: Props) {
  const {
    index,
    title,
    slug,
    price,
    stock,
    productId,
    sku,
    thumbnailImages,
    invertedImages,
    isFullScreen,
    productTag,
    compareAtPrice,
    isMobileGrid,
    isBackgroundTransparent,
    videoThumbnailAtNumber3,
    contentThumbnailAtNumber3,
    thumbnailVariantsImages,
    variants,
  } = props;

  const {
    purchaseAllowed,
    allProductReview,
    allProductShopify,
    allProductShopifyVariant,
  }: {
    purchaseAllowed: any;
    allProductReview: any;
    allProductShopify: any;
    allProductShopifyVariant: any;
  } = useContext(SettingsContext);

  const layout = useGlobalLayoutQuery();
  const colorSale = layout?.colorPriceSale;
  const locale = useLocale();
  const [showVariantColor, setShowVariantColor] = useState(false);
  const [imgSpring, setImgSpring] = useSpring(() => ({
    transform: 'translateX(-100%)',
    config: {
      tension: 152,
      friction: 31,
    },
  }));

  const [imgSpringVariant, setImgSpringVariant] = useSpring(() => ({
    transform: 'translateX(-100%)',
    width: '100%',
    height: '89%',
    config: {
      tension: 152,
      friction: 31,
    },
  }));

  const [imgSpringVariantHorizontal, setImgSpringVariantHorizontal] = useSpring(() => ({
    transform: 'translateY(+50%)',
    opacity: 0,
    width: '100%',
    height: '20%',
    config: {
      tension: 200,
      friction: 31,
    },
  }));

  const [imgSpringVariantVertical, setImgSpringVariantVertical] = useSpring(() => ({
    transform: 'translateX(+100%)',
    width: '40%',
    config: {
      tension: 152,
      friction: 31,
    },
  }));

  const [isTransition, setTransition] = useState({
    img: '',
    color: '',
  });

  const [thumbnail, thumbnailInView] = useInView({
    threshold: 0,
  });

  const handleMouseEnter = () => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) {
      setImgSpring({ transform: 'translateX(0%)' });
      setShowVariantColor(true);
      setImgSpringVariantHorizontal({ transform: 'translateX(0%)', opacity: 1 });
      setImgSpringVariantVertical({ transform: 'translateX(0%)' });
    }
  };

  const handleMouseLeave = () => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) {
      setImgSpring({ transform: 'translateX(-100%)' });
      setShowVariantColor(false);
      setImgSpringVariantHorizontal({ transform: 'translateX(+50%)', opacity: 0 });
      setImgSpringVariantVertical({ transform: 'translateX(+100%)' });
    }
  };

  let pointReview = 0;
  let quantityReview = 0;

  let checkReview = [];
  if (productId) {
    let product_id = decode(productId);
    checkReview = allProductReview?.filter(item => item?.productId == product_id.id);
  }
  if (checkReview?.length > 0) {
    checkReview.map(item => {
      pointReview = pointReview + item?.reviewRating;
      quantityReview = quantityReview + 1;
    });
  }

  function checkQuantity(sku: string) {
    let item = allProductShopifyVariant?.filter(item => item?.includes(sku));
    if (item?.length > 0) {
      item = JSON?.parse(item[0]);
      let checkAvailable = item?.filter(item => item?.node?.quantityAvailable > 0);
      if (checkAvailable?.length > 0) {
        return 1;
      }
    }
    return 0;
  }

  const removeDuplicateImages = (dataArray: any) => {
    if (dataArray?.length > 0) {
      const seenColors = new Set();
      return dataArray.filter(item => {
        const color = item.variantTitle.split(' / ')[1]; // Ambil warna dari variantTitle
        const duplicate = seenColors.has(color);
        seenColors.add(color);
        return !duplicate;
      });
    } else {
      return dataArray;
    }
  };

  const onFilterVariantImage = (item: any) => {
    setTransition({
      img: item.thumbnailforHover.fluid.src,
      color: item.variantTitle.split('/')[1],
    });
  };

  const ImageSwitcher = () => {
    let allVariantColor = removeDuplicateImages(thumbnailVariantsImages);

    return (
      <>
        {allVariantColor?.map((item: any, i: number) => (
          <Link
            key={i}
            to={`/${slug}?color=${
              item.variantTitle
                .replaceAll(' ', '')
                .replaceAll('#', '')
                .toLowerCase()
                .split('/')[1]
            }`}
          >
            {item.variantTitle.split('/')[1] === isTransition.color && (
              <ColorItemHover>{item.variantTitle.split('/')[1]}</ColorItemHover>
            )}
            <BoxColorItem
              onMouseEnter={() => {
                onFilterVariantImage(item);

                setImgSpringVariant({ transform: 'translateX(0%)', immediate: false });
              }}
              onMouseLeave={() => {
                setTransition({
                  ...isTransition,
                  color: ' ',
                });
                setImgSpringVariant({ transform: `translateX(-100%)`, immediate: true });
              }}
            >
              <ColorItem
                src={item.thumbnailVariantColor.fluid.src}
                alt={`${item.thumbnailVariantColor.fluid.src}`}
              />
            </BoxColorItem>
          </Link>
        ))}
      </>
    );
  };

  return (
    <Wrapper
      onMouseOver={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      ref={thumbnail}
    >
      {variants && variants[0]?.title?.includes(' / ') && showVariantColor && (
        <VariantWrapper style={{ ...imgSpringVariantHorizontal }} background={paper}>
          <ImageSwitcher />
        </VariantWrapper>
      )}

      <Link to={`/${slug}/`}>
        <ImagesBlock invertedImages={invertedImages} isFullScreen={isFullScreen}>
          <ImgCropper style={{ ...imgSpring }}>
            {index === 2 &&
            videoThumbnailAtNumber3 != undefined &&
            contentThumbnailAtNumber3 === true ? (
              <>
                <VideoPlayer controls={false} autoPlay loop playsInline muted preload="meta">
                  <source src={videoThumbnailAtNumber3} type="video/mp4" />
                </VideoPlayer>
              </>
            ) : (
              <>
                {thumbnailInView && <StyledImg fluid={thumbnailImages[0].fluid} loading="eager" />}
              </>
            )}
          </ImgCropper>
          <ImgCropper style={{ ...imgSpring }}>
            {thumbnailInView && thumbnailImages[1]?.fluid && (
              <StyledImg fluid={thumbnailImages[1].fluid} loading="eager" />
            )}
          </ImgCropper>
          {isTransition.img != '' && (
            <ImageVariant
              src={isTransition.img}
              alt={isTransition.img}
              style={{ ...imgSpringVariant }}
            />
          )}
        </ImagesBlock>
        <TextBlock
          isMobileGrid={isMobileGrid}
          isFullScreen={isFullScreen}
          isBackgroundTransparent={isBackgroundTransparent}
        >
          <p className="thumbnailTitle">{title}</p>
          {purchaseAllowed?.allowed ? (
            <WrapperStarAndPrice>
              <div className="priceRow">
                {Number(compareAtPrice) > Number(price) ? (
                  <ProductPrice style={{ color: colorSale }}>
                    {formatPrice(price, '€')}
                  </ProductPrice>
                ) : (
                  <ProductPrice>{formatPrice(price, '€')}</ProductPrice>
                )}

                {Number(compareAtPrice) > Number(price) && (
                  <ProductPrice isDiscount="potato">
                    {formatPrice(compareAtPrice, '€')}
                    <div className="strikeTrough" />
                  </ProductPrice>
                )}
              </div>
              {pointReview > 0 ? (
                <StarsReview starsQuantity={5} totalStars={pointReview / quantityReview} />
              ) : null}
            </WrapperStarAndPrice>
          ) : null}
        </TextBlock>
      </Link>
      <RibbonWrapper>
        {productTag && productTag?.toLowerCase() !== 'no' && (
          <TagRibbon>
            <p>{productTag}</p>
          </TagRibbon>
        )}
      </RibbonWrapper>
    </Wrapper>
  );
}

const VariantWrapper = styled(animated.div)`
  position: absolute;
  top: ${mobileVW(195)};
  left: 0;
  width: 100%;
  margin-bottom: ${desktopVW(10)};
  z-index: 2;
  display: flex;
  background-color: #f2efec;
  display: flex;
  justify-content: center;
  ${desktopBreakpoint} {
    top: auto;
    bottom: ${desktopVW(40)};
    padding-top: ${desktopVW(20)};
    background: url(${({ background }) => background});
    background-repeat: repeat;
  }
`;
const VariantWrapper2 = styled(animated.div)`
  position: absolute;
  top: ${mobileVW(195)};
  right: 0;
  width: 100%;
  margin-bottom: ${desktopVW(10)};
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${desktopBreakpoint} {
    top: auto;
    width: ${desktopVW(40)};
    bottom: ${desktopVW(40)};
    padding-left: ${desktopVW(20)};
    padding-top: ${desktopVW(20)};
  }
`;

const BoxColorItem = styled.div`
  width: ${desktopVW(70)};
  height: ${desktopVW(55)};
`;

const ColorItem = styled.img`
  width: ${mobileVW(20)};
  height: ${mobileVW(20)};
  margin-left: ${mobileVW(5)};
  border-radius: ${mobileVW(3)};
  display: flex;
  ${desktopBreakpoint} {
    width: ${desktopVW(55)};
    height: ${desktopVW(55)};
    margin-left: 0;
    border-radius: ${desktopVW(3)};
    display: flex;
  }
`;

const ImageVariant = styled(animated.img)`
  display: none;
  position: absolute;
  width: 100%;
  height: 89%;
  top: 0;
  left: 0;
  object-fit: cover;
  animation-duration: 0.7s;
  ${desktopBreakpoint} {
    display: flex;
  }
`;
const ColorItemHover = styled.div`
  position: absolute;
  top: ${desktopVW(65)};
  z-index: 10;
  color: #6e6b6b;
  font-size: ${desktopVW(15)};
  background-color: #f2efec;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  padding: ${desktopVW(5)} ${desktopVW(10)} ${desktopVW(6)} ${desktopVW(10)};
  border-radius: ${desktopVW(5)};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const ColorItemHover2 = styled.div`
  position: absolute;
  right: ${desktopVW(70)};
  z-index: 10;
  color: #6e6b6b;
  font-size: ${desktopVW(15)};
  background-color: #f2efec;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  padding: ${desktopVW(5)} ${desktopVW(10)} ${desktopVW(6)} ${desktopVW(10)};
  border-radius: ${desktopVW(5)};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const Link = styled(LinkTool)`
  width: 100%;
  height: 100%;

  ${desktopBreakpoint} {
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint} {
    //position: absolute;
    height: 100%;
    width: 100%;
    left: auto;
    top: 0;
    right: 0;
  }
`;

const WrapperStarAndPrice = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.article`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  ${desktopBreakpoint} {
  }
`;

const ImagesBlock = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: ${({ invertedImages }) => (invertedImages ? 'row-reverse' : 'row')};
  width: 200%;
  height: ${({ isFullScreen }) => (isFullScreen ? '88%' : '72.2%')};

  ${desktopBreakpoint} {
    height: 89%;
  }
`;

const TextBlock = styled.div`
  width: 100%;  
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  padding-top: ${mobileVW(5)};
  padding-bottom: 0;
  //height: ${mobileVW(80)};
  height: ${({ isFullScreen }) => (isFullScreen ? 'auto' : `${mobileVW(80)}`)};
  ${({ isMobileGrid }) => (isMobileGrid ? 'position: absolute;' : null)}
  bottom: 0;
  left: 0;
  background-color: ${({ isBackgroundTransparent }) =>
    isBackgroundTransparent ? 'transparent' : colors.frappuccino};

  .priceRow{
    display: flex;
    flex-direction: row;

  }

  p{
    color: ${colors.black};
    line-height: 150%;
    font-size: ${mobileVW(15)};
    font-family: 'messinaMonoLight', "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)}; 
      padding-top: 0;
    }
  }


  ${desktopBreakpoint}{
    padding-top: ${desktopVW(10)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
    height: auto;
  }
`;

const ProductPrice = styled.div`
  position: relative;
  display: inline;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  margin-top: 2px;
  font-size: ${mobileVW(15)};
  font-weight: 300;
  margin-right: 2px;
  color: ${colors.subtleGrey};
  padding: ${mobileVW(5)} ${mobileVW(10)} ${mobileVW(10)} 0;
  ${({ isDiscount }) => (isDiscount ? 'opacity: 0.5;' : null)}

  .strikeTrough {
    width: 90%;
    height: ${mobileVW(1)};
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-3%, -50%);
    background-color: ${colors.subtleGrey};

    ${desktopBreakpoint} {
      height: ${desktopVW(1)};
      top: 49%;
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    font-size: ${desktopVW(15)};
    top: 50%;
    padding: ${desktopVW(5)} ${desktopVW(7)} ${desktopVW(5)} 0;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: auto;

  ${desktopBreakpoint} {
  }
`;

const ImgCropper = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.noItWasNotTheLastBeigeNouance};

  ${desktopBreakpoint} {
  }
`;

const RibbonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: ${mobileVW(10)};
  right: ${mobileVW(10)};

  ${desktopBreakpoint} {
    top: ${desktopVW(20)};
    right: ${desktopVW(20)};
  }
`;

const SaleRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.cappuccino};
    background-color: ${colors.black};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-bottom: ${desktopVW(5)};
  }
`;

const TagRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.black};
    background-color: ${colors.frappuccino};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-bottom: ${desktopVW(5)};
  }
`;
